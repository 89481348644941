// Generated by Framer (f318921)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-otSw4"

const variantClassNames = {F1pZenNPg: "framer-v-1dqn2xv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "F1pZenNPg", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 64, intrinsicWidth: 64, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 96, pixelWidth: 96, src: "https://framerusercontent.com/images/9KVLtYEmTWNUuWGbEkcaF3Rb1Bo.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1dqn2xv", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"F1pZenNPg"} ref={ref ?? ref1} style={{filter: "drop-shadow(-3px -3px 2px rgba(0,0,0,0.25))", WebkitFilter: "drop-shadow(-3px -3px 2px rgba(0,0,0,0.25))", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-otSw4.framer-1o54uf4, .framer-otSw4 .framer-1o54uf4 { display: block; }", ".framer-otSw4.framer-1dqn2xv { height: 35px; overflow: visible; position: relative; width: 35px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 35
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYmcA3FlYY: React.ComponentType<Props> = withCSS(Component, css, "framer-otSw4") as typeof Component;
export default FramerYmcA3FlYY;

FramerYmcA3FlYY.displayName = "Variant 1";

FramerYmcA3FlYY.defaultProps = {height: 35, width: 35};

addFonts(FramerYmcA3FlYY, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})